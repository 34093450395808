import React, { Component, useEffect, useState , useRef } from 'react';
import './App.css';
import ReactAudioPlayer from 'react-audio-player';
import {useLocation } from 'react-router-dom';


export default class App extends Component {
    static displayName = App.name;
    
    constructor(props) {
        super(props);
        this.textsRef = React.createRef();
        this.state = { 
            GetTSData:[],
            GetTsStatus:false,
            GetTSStatusRenk:"",
            GetTSMessage:[],
            overflowIndices: [],

            GetSelfHostData:[],
            GetSelfStatus:false,
            GetSelfHostStatusRenk:"",
            GetSelfMessage:[],

            GetwscktData:[],
            GetwsckStatus:false,
            GetwscktStatusRenk:"",
            GetwsckMessage:[],

            GetSumData:[],
            GetSumStatus:false,
            GetSumDataRenk:"",
            GetSumMessage:[],

            GetCC:[],
            GetCCStatus:false,
            GetCCStatusRenk:"",
            GetCCMessage:[],

            GeSf:[],
            GetSFStatus:false,
            GeSfRenk:"",
            GetSFMessage:[],

            GetSportLangStatusData: [], GetSportStatusData: [], GetLiveLangStatusData: [], GetLiveStatusData: [], loading: true, GetSportStatus: false, GetSportLangStatus: false, GetLiveLangStatus: false, GetLiveStatus: false, buttondisable: false, GetSportLangStatusRenk: "", GetLiveLangStatusRenk: "", GetSportStatusRenk: "", GetLiveStatusRenk:""  };
    }


    
  
    componentDidMount() {
        this.GetSportLangStatusFunc();
        this.GetSportStatusFunc();
        this.GetLiveLangStatusFunc();
        this.GetLiveStatusFunc();

         if(window.location.pathname === "/kate") {
            this.GeSfFunc();
            this.GetCCFunc();
            this.GetSumDataFunc();
            this.GetwscktFunc();
            this.GetSelfHostFunc();
            this.GetTSFunc();
         }
    }



    YenileFunc = async () => {   
            this.setState({ buttondisable: true });

            this.GetSportLangStatusFunc();
            this.GetSportStatusFunc();
            this.GetLiveLangStatusFunc();
            this.GetLiveStatusFunc();
            if(window.location.pathname === "/kate") {
                this.GeSfFunc();
                this.GetCCFunc();
                this.GetSumDataFunc();
                this.GetwscktFunc();
                this.GetSelfHostFunc();
                this.GetTSFunc();
             }


        setTimeout(
            function () {
                this.setState({ buttondisable: false });
            }
                .bind(this),
            15000
        );


    }



    GetTSModalOpen = async () => {
        this.GetTSFunc();
        this.setState({GetTsStatus:true})
    }


    GetTSModalClose = async () => {
        this.setState({GetTsStatus:false})
    }

    GetSelfHostOpen = async () => {
        this.GetSelfHostFunc();
        this.setState({GetSelfStatus:true})
    }
    GetSelfHostClose = async () => {
        this.setState({GetSelfStatus:false})
    }

    GetwscktOpen = async () => {
        this.GetwscktFunc();
        this.setState({GetwsckStatus:true})
    }
    GetwscktClose = async () => {
        this.setState({GetwsckStatus:false})
    }

    GetSumDataOpen = async () => {
        this.GetSumDataFunc();
        this.setState({GetSumStatus:true})
    }
    GetSumDataClose = async () => {
        this.setState({GetSumStatus:false})
    }

    GetCCOpen = async () => {
        this.GetCCFunc();
        this.setState({GetCCStatus:true})
    }
    GetCCClose = async () => {
        this.setState({GetCCStatus:false})
    }

    GeSfOpen = async () => {
        this.GeSfFunc();
        this.setState({GetSFStatus:true})
    }
    GeSfClose = async () => {
        this.setState({GetSFStatus:false})
    }




    GetLiveStatusModalOpen = async () => {
        try {
            const response = await fetch('/GetStatus/GetLiveStatus');
            const data = await response.json();
            const renk3 = data.Lci.IsDown;

            this.setState({ GetLiveStatusData: data, GetLiveStatusRenk: renk3 });
            this.setState({ GetLiveStatus: true });
        } catch (er) {
            this.setState({ GetLiveStatusRenk: true })
        }

    };

    

    GetLiveStatusModalClose = () => {
        this.setState({ GetLiveStatus: false });
    };


    GetSportLangStatusModalOpen = async () => {
        try {
            const response = await fetch('/GetStatus/GetSportLangStatus');
            const data = await response.json();
            const renk = data.Lci.IsDown;
            this.setState({ GetSportLangStatusData: data, GetSportLangStatusRenk: renk });
            this.setState({ GetSportLangStatus: true });
           

        } catch (er) {
            this.setState({ GetSportLangStatusRenk: true })
        }
       
    };


    GetSportLangStatusModalClose = () => {
        this.setState({ GetSportLangStatus: false });
    };

    GetLiveLangStatusModalOpen = async () => {
        try {
            const response = await fetch('/GetStatus/GetLiveLangStatus');
            const data = await response.json();
            const renk2 = data.Lci.IsDown;
            this.setState({ GetLiveLangStatusData: data, GetLiveLangStatusRenk: renk2 });
            this.setState({ GetLiveLangStatus: true });

        } catch (er) {
            this.setState({ GetLiveLangStatusRenk: true })
        }
       
    };
    GetLiveLangStatusModalClose = () => {
        this.setState({ GetLiveLangStatus: false });
    };

    handleClick = (e) => {
        console.log(e.target.classList.value)
        if(e.target.classList.value.split(" ")[2]){
            e.currentTarget.classList.remove("fa-chevron-up")
            e.currentTarget.classList.add("fa-chevron-down")
            e.currentTarget.classList.remove("click-state")
        }else{
            e.currentTarget.classList.remove("fa-chevron-down")
            e.currentTarget.classList.add("fa-chevron-up")
            e.currentTarget.classList.add("click-state")
        }
    }
  

    handleClickDiv(e) {
        const parent = e.currentTarget.nextElementSibling.classList.value;
        if(parent.split(" ")[2] !== "click-state") {
            e.currentTarget.nextElementSibling.classList.remove("fa-chevron-down")
            e.currentTarget.nextElementSibling.classList.add("fa-chevron-up")
            e.currentTarget.nextElementSibling.classList.add("click-state")
        } else{
            e.currentTarget.nextElementSibling.classList.remove("click-state")
            e.currentTarget.nextElementSibling.classList.add("fa-chevron-down")
            e.currentTarget.nextElementSibling.classList.remove("fa-chevron-up")
        }

      }


    

    GetSportStatusModalOpen = async () => {
        try {
            const response = await fetch('/GetStatus/GetSportStatus');
            const data = await response.json();
            const renk4 = data.Lci.IsDown;
            this.setState({ GetSportStatusData: data, GetSportStatusRenk: renk4, loading: false });
            this.setState({ GetSportStatus: true });

        } catch (er) {
            this.setState({ GetSportStatusRenk: true, loading: false })
        }
      
    };
    GetSportStatusModalClose = () => {
        this.setState({ GetSportStatus: false });
    };
    

    render() {
            const { texts, overflowIndices } = this.state;

        return (
            this.state.loading ?
                <div className="LoadingScreen">
                   <div>Loading ...</div>
                 </div>
                
                :

                <div className="containerArea">   
                    {
                        this.state.GetLiveStatus || this.state.GetSportStatus || this.state.GetSportLangStatus || this.state.GetLiveLangStatus || 
                       (this.state.GetTsStatus && this.state.GetTSData.statusCode === 200 ) || (this.state.GetSelfStatus && this.state.GetSelfHostData.statusCode === 200) ||
                         ( this.state.GetSumStatus  && this.state.GetSumData.statusCode === 200) || (this.state.GetwsckStatus && this.state.GetwscktData.statusCode ===200) 
                          || (this.state.GetCCStatus && this.state.GetCC.statusCode === 200) ||  (this.state.GetSFStatus && this.state.GeSf.statusCode === 200)
                         ?
                            <div></div>
                            :
                            <div className="ControllerNameArea">
                                <div className="FirstArea">
                                    <div id="GetSportLangStatus" onClick={this.GetSportLangStatusModalOpen} className={this.state.GetSportLangStatusRenk == false ? "ControllerStyleFalse" : "ControllerStyleTrue"} >Spor Dil Program</div>
                                    <div id="GetLiveLangStatus" className={this.state.GetLiveLangStatusRenk == false ? "ControllerStyleFalse" : "ControllerStyleTrue"} onClick={this.GetLiveLangStatusModalOpen} >Canli Dil Program</div>
                                </div>
                                <div className="SecondArea">
                                    {
                                        this.state.GetLiveStatusRenk == true ?
                                            <div>
                                                <ReactAudioPlayer
                                                    src="./alarm.mp3"
                                                    autoPlay 
                                                    loop
                                                />
                                                </div>

                                            :
                                            ""

                                    }


                                 
                                    <div id="GetSportStatus" className={this.state.GetSportStatusRenk == false ? "ControllerStyleFalse" : "ControllerStyleTrue"} onClick={this.GetSportStatusModalOpen} >Spor Oran Program</div>
                                    <div id="GetLiveStatus" className={this.state.GetLiveStatusRenk == false ? "ControllerStyleFalse" : "ControllerStyleTrue"} onClick={this.GetLiveStatusModalOpen} >Canli Oran Program</div>
                                </div>
                                <button onClick={this.YenileFunc} className={this.state.buttondisable ? "disablebutton" : "Yenile"}>Yenile</button>
                                {
                                    window.location.pathname === "/kate" ? 
                                    <div className='LocationKate'>
                                    <div onClick={this.GetTSModalOpen} id={this.state.GetTSStatusRenk == false ? "Green" : "Red"}  className='GetTS'>TS</div>
                                    <div onClick={this.GetSelfHostOpen} id={this.state.GetSelfHostStatusRenk == false ? "Green" : "Red"}   className='GetSelfHost'>SelfHost</div>
                                    <div onClick={this.GetwscktOpen}  id={this.state.GetwscktStatusRenk == false ? "Green" : "Red"} className='Getwsckt'>Wsckt</div>
                                    <div onClick={this.GetSumDataOpen} id={this.state.GetSumDataRenk == false ? "Green" : "Red"} className='GetSumData'>GetSumData</div>
                                    <div onClick={this.GetCCOpen} id={this.state.GetCCStatusRenk == false ? "Green" : "Red"} className='GetCC'>GetCC</div>
                                    <div onClick={this.GeSfOpen} id={this.state.GeSfRenk == false ? "Green" : "Red"}  className='GeSf'>GeSf</div>
                                    </div>
                                    :
                                    ""
                                }
                            </div>

                    }

                    {
                        this.state.GetSportStatus ?
                            <div className="Modal">
                                <div className="modalText"><span className="modalSpan">Spor Oran Program</span><button onClick={this.GetSportStatusModalClose} className="closeModal">X</button></div>
                                <div className={this.state.GetSportStatusData.Lci.IsDown == false ? "GreenBack" : "RedBack"}>                            
                                    {Object.keys(this.state.GetSportStatusData.Lci.LastMasseges).map((keyName, i) => (
                                        <div key={i} className="MessageText"><div className= { this.state.GetSportStatusData.Lci.IsDown == false ? "LogWarningGreen" : "LogWarningRed"}>!</div><div id='TextCapacity'>{this.state.GetSportStatusData.Lci.LastMasseges[keyName]}</div></div>
                                    ))}
                                </div>

                            </div>
                            :
                            this.state.GetSportLangStatus ?
                                <div className="Modal">
                                    <div className="modalText"><span className="modalSpan">Spor Dil Program</span><button onClick={this.GetSportLangStatusModalClose} className="closeModal">X</button></div>
                                    <div className={this.state.GetSportLangStatusData.Lci.IsDown == false ? "GreenBack" : "RedBack"}>                            
                                        {Object.keys(this.state.GetSportLangStatusData.Lci.LastMasseges).map((keyName, i) => (
                                            <div key={i} className="MessageText"><div className= { this.state.GetSportLangStatusData.Lci.IsDown == false ? "LogWarningGreen" : "LogWarningRed"}>!</div><div id='TextCapacity'>{this.state.GetSportLangStatusData.Lci.LastMasseges[keyName]}</div></div>
                                        ))}
                                     </div>
                                </div>
                                :
                                this.state.GetLiveLangStatus ?
                                    <div className="Modal">
                                        <div className="modalText"><span className="modalSpan">Canli Dil Program</span><button onClick={this.GetLiveLangStatusModalClose} className="closeModal">X</button></div>
                                        <div className={this.state.GetLiveLangStatusData.Lci.IsDown == false ? "GreenBack" : "RedBack"}>                            
                                            {Object.keys(this.state.GetLiveLangStatusData.Lci.LastMasseges).map((keyName, i) => (
                                                <div key={i} className="MessageText"><div className= { this.state.GetLiveLangStatusData.Lci.IsDown == false ? "LogWarningGreen" : "LogWarningRed"}>!</div><div id='TextCapacity'>{this.state.GetLiveLangStatusData.Lci.LastMasseges[keyName]}</div></div>
                                             ))}
                                        </div>
                                    </div>
                                    :
                                    this.state.GetLiveStatus ? 
                                        <div className="Modal">
                                            <div className="modalText"><span className="modalSpan">Canli Oran Program</span><button onClick={this.GetLiveStatusModalClose} className="closeModal">X</button></div>
                                            <div className={this.state.GetLiveStatusData.Lci.IsDown == false ? "GreenBack" : "RedBack"}>                            
                                                {Object.keys(this.state.GetLiveStatusData.Lci.LastMasseges).map((keyName, i) => (
                                                    <div key={i} className="MessageText"><div className= { this.state.GetLiveStatusData.Lci.IsDown == false ? "LogWarningGreen" : "LogWarningRed"}>!</div><div id='TextCapacity'>{this.state.GetLiveStatusData.Lci.LastMasseges[keyName]}</div></div>
                                                ))}
                                            </div>
                                        </div>
                                        :
                                        this.state.GetTsStatus && this.state.GetTSData.statusCode === 200 ? 
                                        <div className="Modal">
                                            <div className="modalText"><span className="modalSpan">TS</span><button onClick={this.GetTSModalClose} className="closeModal">X</button></div>
                                            <div ref={this.textsRef}  className={this.state.GetTSStatusRenk === false ? "GreenBack" : "RedBack"}>
                                                {Object.keys(this.state.GetTSMessage).map((keyName, index) => (
                                                    <div key={index}  className="MessageText"><div className= { this.state.GetTSStatusRenk === false ? "LogWarningGreen" : "LogWarningRed"}>!</div><div  onClick={this.handleClickDiv}  className={overflowIndices.includes(index) ? 'custom-class' : ''} id={'TextCapacity'}>{this.state.GetTSData.Lci.LastMasseges[keyName]}</div><i onClick={this.handleClick} id='BtnCustom' class="fa-solid fa-chevron-down"></i></div>
                                                ))}
                                            </div>
                                        </div>
                                        :
                                        this.state.GetSelfStatus && this.state.GetSelfHostData.statusCode === 200 ? 
                                        <div className="Modal">
                                            <div className="modalText"><span className="modalSpan">SelfHost</span><button onClick={this.GetSelfHostClose} className="closeModal">X</button></div>
                                            <div ref={this.textsRef}  className={this.state.GetSelfHostStatusRenk == false ? "GreenBack" : "RedBack"}>                            
                                                {Object.keys(this.state.GetSelfMessage).map((keyName, index) => (
                                                     <div key={index}  className="MessageText"><div className= { this.state.GetSelfHostStatusRenk === false ? "LogWarningGreen" : "LogWarningRed"}>!</div><div  onClick={this.handleClickDiv}  className={overflowIndices.includes(index) ? 'custom-class' : ''} id={'TextCapacity'}>{this.state.GetSelfHostData.Lci.LastMasseges[keyName]}</div><i onClick={this.handleClick} id='BtnCustom' class="fa-solid fa-chevron-down"></i></div>
                                                ))}
                                            </div>
                                        </div>
                                          :
                                          this.state.GetSumStatus  && this.state.GetSumData.statusCode === 200?  
                                          <div className="Modal">
                                              <div className="modalText"><span className="modalSpan">Sum Data</span><button onClick={this.GetSumDataClose} className="closeModal">X</button></div>
                                              <div ref={this.textsRef} className={this.state.GetSumData.Lci.IsDown == false ? "GreenBack" : "RedBack"}>
                                                  {Object.keys(this.state.GetSumMessage).map((keyName, index) => (
                                                    <div key={index}  className="MessageText"><div className= { this.state.GetSumData.Lci.IsDown === false ? "LogWarningGreen" : "LogWarningRed"}>!</div><div  onClick={this.handleClickDiv}  className={overflowIndices.includes(index) ? 'custom-class' : ''} id={'TextCapacity'}>{this.state.GetSumData.Lci.LastMasseges[keyName]}</div><i onClick={this.handleClick} id='BtnCustom' class="fa-solid fa-chevron-down"></i></div>
                                                  ))}
                                              </div>
                                          </div>
                                        :
                                        this.state.GetwsckStatus && this.state.GetwscktData.statusCode ===200  ? 
                                        <div className="Modal">
                                            <div className="modalText"><span className="modalSpan">Wsckt</span><button onClick={this.GetwscktClose} className="closeModal">X</button></div>
                                            <div  ref={this.textsRef}  className={this.state.GetwscktData.Lci.IsDown == false ? "GreenBack" : "RedBack"}>
                                                {Object.keys(this.state.GetwsckMessage).map((keyName, index) => (
                                                    <div key={index}  className="MessageText"><div className= { this.state.GetwscktData.Lci.IsDown  === false ? "LogWarningGreen" : "LogWarningRed"}>!</div><div  onClick={this.handleClickDiv}  className={overflowIndices.includes(index) ? 'custom-class' : ''} id={'TextCapacity'}>{this.state.GetwscktData.Lci.LastMasseges[keyName]}</div><i onClick={this.handleClick} id='BtnCustom' class="fa-solid fa-chevron-down"></i></div>

                                                ))}
                                            </div>
                                        </div>
                                          :
                                          this.state.GetCCStatus && this.state.GetCC.statusCode === 200? 
                                          <div className="Modal">
                                              <div className="modalText"><span className="modalSpan">GetCC</span><button onClick={this.GetCCClose} className="closeModal">X</button></div>
                                              <div ref={this.textsRef}  className={this.state.GetCC.Lci.IsDown == false ? "GreenBack" : "RedBack"}>
                                                  {Object.keys(this.state.GetCCMessage).map((keyName, index) => (
                                                    <div key={index}  className="MessageText"><div className= { this.state.GetCC.Lci.IsDown === false ? "LogWarningGreen" : "LogWarningRed"}>!</div><div  onClick={this.handleClickDiv}  className={overflowIndices.includes(index) ? 'custom-class' : ''} id={'TextCapacity'}>{this.state.GetCC.Lci.LastMasseges[keyName]}</div><i onClick={this.handleClick} id='BtnCustom' class="fa-solid fa-chevron-down"></i></div>

                                                  ))}
                                              </div>
                                          </div>

                                           :
                                          this.state.GetSFStatus && this.state.GeSf.statusCode === 200? 
                                          <div className="Modal">
                                              <div className="modalText"><span className="modalSpan">GeSf</span><button onClick={this.GeSfClose} className="closeModal">X</button></div>
                                              <div ref={this.textsRef} className={this.state.GeSf.Lci.IsDown == false ? "GreenBack" : "RedBack"}>
                                                  {Object.keys(this.state.GetSFMessage).map((keyName, index) => (
                                                     <div key={index}  className="MessageText"><div className= { this.state.GeSf.Lci.IsDown === false ? "LogWarningGreen" : "LogWarningRed"}>!</div><div  onClick={this.handleClickDiv}  className={overflowIndices.includes(index) ? 'custom-class' : ''} id={'TextCapacity'}>{this.state.GeSf.Lci.LastMasseges[keyName]}</div><i onClick={this.handleClick} id='BtnCustom' class="fa-solid fa-chevron-down"></i></div>
                                                  ))}
                                              </div>
                                          </div>
                                      :

                            <div></div>
                    }
                
                </div>

        );
    }


    async GetSportLangStatusFunc() {

        try {
            const response = await fetch('/GetStatus/GetSportLangStatus');
            const data = await response.json();
            const renk = data.Lci.IsDown;
            const message = data.Lci.LastMasseges;
            this.setState({ GetSportLangStatusData: data,  GetSportLangStatusRenk: renk });
            setTimeout(() => {
                if(data.statusCode !== 200){
                    this.setState({GetSportLangStatusRenk: true });
                }
               }, 10000,[]);
            
        } catch (er) {
         
            this.setState({ GetSportLangStatusRenk: true })
        }
      
    }
    async GetLiveLangStatusFunc() {
        try {
            const response = await fetch('/GetStatus/GetLiveLangStatus');
            const data = await response.json();
            const renk2 = data.Lci.IsDown;
            this.setState({ GetLiveLangStatusData: data, GetLiveLangStatusRenk: renk2 });
            setTimeout(() => {
                if(data.statusCode !== 200){
                    this.setState({GetLiveLangStatusRenk: true });
                }
               }, 10000,[]);

        } catch (er) {
            this.setState({ GetLiveLangStatusRenk: true })
        }
       
    }
    async GetLiveStatusFunc() {
        try {
            const response = await fetch('/GetStatus/GetLiveStatus');
            const data = await response.json();
            const renk3 = data.Lci.IsDown;
            this.setState({ GetLiveStatusData: data, GetLiveStatusRenk: renk3 ,loading: false });
            setTimeout(() => {
                if(data.statusCode !== 200){
                    this.setState({GetLiveStatusRenk: true ,loading: false })
                }
               }, 10000,[]);

        } catch (er) {
            this.setState({ GetLiveStatusRenk: true , loading: false  })
        }
   
    }
    async GetSportStatusFunc() {
        try {
            const response = await fetch('/GetStatus/GetSportStatus');
            const data = await response.json();
            const renk4 = data.Lci.IsDown;
            this.setState({ GetSportStatusData: data, GetSportStatusRenk: renk4});
            setTimeout(() => {
                if(data.statusCode !== 200){
                    this.setState({GetSportStatusRenk: true});
                }
               }, 10000,[]);



        } catch (er) {
            this.setState({ GetSportStatusRenk: true  })
        }

    }


    async GeSfFunc() {
        try {
            const response = await fetch('/GetStatus/GeSf');
            const data = await response.json();
            const renk10 = data.Lci.IsDown;
            const message = data.Lci.LastMasseges;
            this.setState({ GeSf :  data, GetSFMessage:message, GeSfRenk: renk10 });
            setTimeout(() => {
                if(data.statusCode !== 200){
                    this.setState({ GetSFMessage:"", GeSfRenk: true });
                }
               }, 10000,[]);

        } catch (er) {
            this.setState({ GeSfRenk: true ,GetSFMessage:""  })
        }
        if(this.textsRef.current !== null) {
            const textsArray = Array.from(this.textsRef.current.children);
            const overflowIndices = textsArray.reduce((indices, textElement, index) => {
              if (textElement.offsetHeight < textElement.scrollHeight) {
                indices.push(index);
              }
              return indices;
            },[]);
        
            this.setState({ overflowIndices });

         }

    }


    async GetCCFunc() {
        try {
            const response = await fetch('/GetStatus/GetCC');
            const data = await response.json();
            const renk9 = data.Lci.IsDown;
            const message = data.Lci.LastMasseges;
            this.setState({ GetCC:  data, GetCCMessage:message, GetCCStatusRenk: renk9 });
            setTimeout(() => {
                if(data.statusCode !== 200){
                    this.setState({ GetCCStatusRenk: true  , GetCCMessage:"" })
                }
               }, 10000,[]);


        } catch (er) {
            this.setState({ GetCCStatusRenk: true  , GetCCMessage:"" })
        }
        if(this.textsRef.current !== null) {
            const textsArray = Array.from(this.textsRef.current.children);
            const overflowIndices = textsArray.reduce((indices, textElement, index) => {
              if (textElement.offsetHeight < textElement.scrollHeight) {
                indices.push(index);
              }
              return indices;
            },[]);
        
            this.setState({ overflowIndices });

         }

    }

 

    async GetSumDataFunc() {
        try {
            const response = await fetch('/GetStatus/GetSumData');
            const data = await response.json();
            const renk8 = data.Lci.IsDown;
            const message = data.Lci.LastMasseges;
            this.setState({ GetSumData:  data, GetSumMessage:message, GetSumDataRenk: renk8 });
            setTimeout(() => {
                if(data.statusCode !== 200){
                    this.setState({ GetSumDataRenk: true , GetSumMessage:""  })
                }
               }, 10000,[]);


        } catch (er) {
            this.setState({ GetSumDataRenk: true , GetSumMessage:""  })
        }
        if(this.textsRef.current !== null) {
            const textsArray = Array.from(this.textsRef.current.children);
            const overflowIndices = textsArray.reduce((indices, textElement, index) => {
              if (textElement.offsetHeight < textElement.scrollHeight) {
                indices.push(index);
              }
              return indices;
            },[]);
        
            this.setState({ overflowIndices });

         }

    }

    

    async GetwscktFunc() {
        try {
            const response = await fetch('/GetStatus/Getwsckt');
            const data = await response.json();
            const renk7 = data.Lci.IsDown;
            const message = data.Lci.LastMasseges;
            this.setState({ GetwscktData:  data, GetwsckMessage:message, GetwscktStatusRenk: renk7 });
            setTimeout(() => {
                if(data.statusCode !== 200){
                    this.setState({ GetwscktStatusRenk: true  , GetwsckMessage:"" })
                }
               }, 10000,[]);


        } catch (er) {
            this.setState({ GetwscktStatusRenk: true  , GetwsckMessage:"" })
        }
        if(this.textsRef.current !== null) {
            const textsArray = Array.from(this.textsRef.current.children);
            const overflowIndices = textsArray.reduce((indices, textElement, index) => {
              if (textElement.offsetHeight < textElement.scrollHeight) {
                indices.push(index);
              }
              return indices;
            },[]);
        
            this.setState({ overflowIndices });

         }

    }
    

    async GetSelfHostFunc() {
        try {
            const response = await fetch('/GetStatus/GetSelfHost');
            const data = await response.json();
            const renk6 = data.Lci.IsDown;
            const message = data.Lci.LastMasseges;
            this.setState({ GetSelfHostData:  data, GetSelfMessage:message, GetSelfHostStatusRenk: renk6 });
            setTimeout(() => {
                if(data.statusCode !== 200){
                    this.setState({ GetSelfHostStatusRenk: true  , GetSelfMessage:"" })
                }
               }, 10000,[]);



        } catch (er) {
            this.setState({ GetSelfHostStatusRenk: true  , GetSelfMessage:"" })
        }

        if(this.textsRef.current !== null) {
            const textsArray = Array.from(this.textsRef.current.children);
            const overflowIndices = textsArray.reduce((indices, textElement, index) => {
              if (textElement.offsetHeight < textElement.scrollHeight) {
                indices.push(index);
              }
              return indices;
            },[]);
        
            this.setState({ overflowIndices });

         }

    }


    
     async GetTSFunc() {
        try {
            const response = await fetch('/GetStatus/GetTS');
            const data =  await response.json();
            const renk5 = data.Lci.IsDown;
            const message = data.Lci.LastMasseges;
            this.setState({ GetTSData:  data, GetTSStatusRenk: renk5, GetTSMessage:message, loading: false });
            setTimeout(() => {
                if(data.statusCode !== 200){
                    this.setState({ GetTSStatusRenk: true , GetTSMessage:"",  loading: false })
                }
               }, 10000,[]);

        } catch (er) {
            this.setState({ GetTSStatusRenk: true , GetTSMessage:"",  loading: false })
          
        }

        if(this.textsRef.current !== null) {
            const textsArray = Array.from(this.textsRef.current.children);
         
            const overflowIndices = textsArray.reduce((indices, textElement, index) => {
              if (textElement.offsetHeight < textElement.scrollHeight) {
                indices.push(index);
              }
              return indices;
            },[]);
        
            this.setState({ overflowIndices });

         }

    }


}

